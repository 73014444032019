export const api_url = process.env.REACT_APP_EKRAN_API_URL; //url for data api
export const api_url_headers = {
  "Content-Type": "application/json",
  "JsonStub-User-Key": process.env.REACT_APP_EKRAN_USER_KEY,
  "JsonStub-Project-Key": process.env.REACT_APP_EKRAN_PROJECT_KEY,
}; //header object for data api qwery
export const dataTimeout = 3_600_000; // timeout for checking new data


export const checksum_url = process.env.REACT_APP_EKRAN_CHECKSUM_API_URL; //url for checksum api
export const checksum_url_headers = {
  "Content-Type": "application/json",
  "JsonStub-User-Key": process.env.REACT_APP_EKRAN_CHECKSUM_USER_KEY,
  "JsonStub-Project-Key": process.env.REACT_APP_EKRAN_CHECKSUM_PROJECT_KEY,
}; //header object for checksum api qwery
export const checksumTimeout = 60_000; // timeout for checking new checksum
