import React from "react";
import { Row, Col } from "@bootstrap-styled/v4";
import styled from "styled-components";

import Quote from "./Quote";
import Names from "./Names";
import Weather from "./Weather";

const Wrapper = styled.footer`
  border-top: 4px solid;
`;
const RowNames = styled(Row)`
  padding-top: 5rem;
  height: 291px;
`;
const RowWeather = styled(Row)`
  padding-top: 2rem;
  height: 355px;
`;
const Footer = (props) => {
  return (
    <Wrapper>
      <RowNames className="border-bottom border-gray">
        <Col md={12}>
          <Quote />
        </Col>
      </RowNames>
      <RowWeather className="align-items-baseline">
        <Col md={6}>
          <Names />
        </Col>
        <Col md={6}>
          <Weather />
        </Col>
      </RowWeather>
    </Wrapper>
  );
};

export default Footer;
