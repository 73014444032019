import React, { useContext } from "react";
import styled from "styled-components";
import { NameDayContext } from "../context";

const Wrapper = styled.div`
  font-size: 4.8rem;
  font-weight: 400;
  line-height: 6rem;
`;
const Names = (props) => {
  const names = useContext(NameDayContext);
  return (
    <Wrapper>
      <p className="label">imieniny:</p>
      {names.length > 0 && names.join(", ")}
    </Wrapper>
  );
};

export default Names;
