import React from "react";
import { Row, Col } from "@bootstrap-styled/v4";
import styled from "styled-components";

import ekran_number from "../ekran_number.png";
import Img from "./UI/Img";
import Clock from "./Clock";

const Wrapper = styled.header`
  border-bottom: 4px solid;
  height: 172px;
`;

const Header = (props) => (
  <Wrapper>
    <Row className="align-items-center h-100">
      <Col md={6}>
        <div>
          <Img alt="Number" src={ekran_number} mw={"244px"} />
        </div>
      </Col>
      <Col md={6}>
        <Clock />
      </Col>
    </Row>
  </Wrapper>
);
export default Header;
