import React from "react";
import styled from "styled-components";

const Wrapper =styled.div`
    text-transform: lowercase;
    p:first-child {
        padding-top: 2rem;
        font-size: 7.4rem;
        font-weight: 500;
        line-height: 1;
        margin-bottom: 0;
    }
    p:last-child {
        font-size: 3rem;
        font-weight: 400;
    }
`;
const dayOfWeek = ["Niedziela","Poniedziałek","Wtorek","Środa","Czwartek","Piątek","Sobota"];
const months = ['Stycznia', 'Lutego', 'Marca', 'Kwietnia', 'Maja', 'Czerwca', 'Lipca', 'Sierpnia', 'Września', 'Październik', 'Listopada', 'Grudnia'];
export default class Clock extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
          hours: '',
          minutes: '',
          weekday: '',
          day: '',
          month: '',
      }
      this.tick();
    }
    componentDidMount() {
      this.intervalID = setInterval(
        () => this.tick(),
        1000
      );
    }
    componentWillUnmount() {
      clearInterval(this.intervalID);
    }
    tick() {
      let date =  new Date();
      this.setState({
        hours: this.addZero(date.getHours()),
        minutes: this.addZero(date.getMinutes()),
        weekday: dayOfWeek[date.getDay()],
        day: date.getDate(),
        month: months[date.getMonth()]
      });
    }

    addZero = function (i) {
        if (i < 10) {
            i = "0" + i;
            return i;
        }
        return i;
    };

    render() {
      return (
          <Wrapper className='d-flex align-items-end flex-column'>
            <p>{this.state.hours} : {this.state.minutes} </p>
            <p>{this.state.day} {this.state.month}, {this.state.weekday}</p>
          </Wrapper>
      );
    }
  }
