import React from "react";
import styled from "styled-components";
const Ul = styled.ul`
  list-style: none;
`;
const Li = styled.li`
  display: flex;
  justify-content: space-between;
  font-weight: 400;
  text-transform: uppercase;
`;
const Info = styled.p`
  font-size: 32px;
  font-weight: 400;
`;
const FirmList = (props) => {
  return (
    <Ul>
      {props.companies.length > 0 &&
        props.companies.map((el, index) => {
          return (
            <Li key={index}>
              <div>
                {el.name}
                {el.description ? (
                  <div className="label">{el.description}</div>
                ) : (
                  ""
                )}
              </div>
              <Info className="text-pale">{el.info}</Info>
            </Li>
          );
        })}
    </Ul>
  );
};

export default FirmList;
