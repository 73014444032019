import React, { useContext } from "react";
import styled from "styled-components";
import { MottoContext } from "../context";
const Wrapper = styled.div`
  p {
    font-size: 4.2rem;
    font-weight: 400;
    line-height: 6.2rem;
  }
  span {
    font-size: 2rem;
    font-weight: 400;
    line-height: 4.6rem;
    text-transform: uppercase;
  }
`;
const Quote = (props) => {
  const motto = useContext(MottoContext);
  return (
    <Wrapper>
      <p>"{motto.content}"</p>
      <span className="text-pale">- {motto.author}</span>
    </Wrapper>
  );
};

export default Quote;
