import "./App.scss";
import styled from "styled-components";
import BootstrapProvider from "@bootstrap-styled/provider";
import { Container } from "@bootstrap-styled/v4";
import React, { useState, useEffect } from "react";

import Header from "./components/Header";
import Footer from "./components/Footer";
import FloorList from "./components/FloorList";
import Loader from "./components/Loader";
import Error from "./components/Error";
import {
  MottoContext,
  FloorsContext,
  WeatherContext,
  NameDayContext,
} from "./context";
import {
  api_url,
  api_url_headers,
  dataTimeout,
  checksum_url,
  checksum_url_headers,
  checksumTimeout,
} from "./settings";

function App() {
  const [motto, setMotto] = useState(MottoContext._currentValue);
  const [floors, setFloors] = useState(FloorsContext._currentValue);
  const [weather, setWeather] = useState(WeatherContext._currentValue);
  const [name_day, setNameDay] = useState(NameDayContext._currentValue);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  function fetchData(url, firstFetch = false) {
    fetch(url, {
      headers: api_url_headers,
    })
      .then((res) =>
        res.json().then((result) => {
          if (result.error) {
            if (firstFetch) {
              const storageData = localStorage.getItem("screen-data");
              if (!storageData) {
                setError(true);
                setLoading(false);
                throw new Error("Fetching error no data in storage");
              }
              result = JSON.parse(storageData);
            } else {
              throw new Error("Fetching error , data not changed");
            }
          } else {
            result.weather.temperature = result.weather.temperature.toString();
            localStorage.setItem("screen-data", JSON.stringify(result));
            setLoading(false);
            setError(false);
          }

          setMotto(result.motto);
          setFloors(result.floors);
          setWeather(result.weather);
          setNameDay(result.name_day);
          setLoading(false);
          setError(false);
        })
      )
      .catch((error) => console.log("error from catch"))

      .finally(() => {
        setTimeout(() => {
          fetchData(url);
        }, dataTimeout);
      });
  }

  function fetchChecksum(url, firstFetch = false, sum = 0) {
    let checksum = sum;
    fetch(url, {
      headers: checksum_url_headers,
    })
      .then((res) =>
        res.json().then((result) => {
          if (result.error) {
            throw new Error("Fetching error no data in storage");
          } else {
            if (!firstFetch && sum !== result) {
              window.location.reload();
            } else {
              checksum = result;
            }
          }
        })
      )
      .catch((error) => (checksum = sum))

      .finally(() => {
        setTimeout(() => {
          fetchChecksum(url, false, checksum);
        }, checksumTimeout);
      });
  }

  useEffect(() => {
    fetchData(api_url, true);
    fetchChecksum(checksum_url, true);
    // eslint-disable-next-line
  }, []);

  const custom_theme = {
    "$font-family-base": '"Poppins", sans-serif',
    "$body-bg": "#2b2a29",
    "$body-color": "#ffffff",
    "$container-max-widths": {
      sm: "1080px",
    },
    "$font-size-base": "3.2rem",
    "$theme-colors": {
      gray: "#515151",
      pale: "#858585",
    },
  };
  return (
    <BootstrapProvider theme={custom_theme}>
      <Wrapper>
        <Container>
          <Header />
          {loading ? (
            <Loader />
          ) : error ? (
            <Error />
          ) : (
            <React.Fragment>
              <FloorsContext.Provider value={floors}>
                <FloorList />
              </FloorsContext.Provider>

              <NameDayContext.Provider value={name_day}>
                <WeatherContext.Provider value={weather}>
                  <MottoContext.Provider value={motto}>
                    <Footer />
                  </MottoContext.Provider>
                </WeatherContext.Provider>
              </NameDayContext.Provider>
            </React.Fragment>
          )}
        </Container>
      </Wrapper>
    </BootstrapProvider>
  );
}

const Wrapper = styled.div`
  height: 1920px;
  ${Container} {
    padding: 0 5.2rem;
  }
`;

export default App;
