import React from "react";

export const MottoContext = React.createContext({
  content: "",
  author: "",
});

export const FloorsContext = React.createContext([]);

export const WeatherContext = React.createContext({
  air_pollution: "",
  temperature: "",
  icon: "",
  label: "",
});

export const NameDayContext = React.createContext([]);
