import rain from "./images/weather/rain.png";
import sun from "./images/weather/sun.png";
import frost from "./images/weather/frost.png";
import thunderstorm from "./images/weather/thunderstorm.png";
import mist from "./images/weather/mist.png";
import fog from "./images/weather/fog.png";
import night from "./images/weather/night.png";
import light_clouds_night from "./images/weather/light-clouds-night.png";
import light_clouds from "./images/weather/light-clouds.png";
import clouds from "./images/weather/clouds.png";
import snow from "./images/weather/snow.png";

const icons = {
  thunderstorm,
  rain,
  frost,
  mist,
  fog,
  night,
  sun,
  "light-clouds-night": light_clouds_night,
  "light-clouds": light_clouds,
  clouds,
  snow,
};

export default icons;
