import React from "react";
import styled from "styled-components";

const Img = props => (
    <Wrapper
         mw={props.mw} className={props.cl ? props.cl : ""}
    >
        <img src={props.src} alt={props.alt} />
    </Wrapper>
);

const Wrapper = styled.div`
    display: flex;
    justyfy-content: center;
    align-items: center;
    max-width: ${p=>p.mw || 'unset'};
    img {
        width: 100%;
    }
`;
export default Img;