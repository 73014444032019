import React, { useContext } from "react";
import styled from "styled-components";

import Img from "./UI/Img";
import toilet from "./../images/toilet.png";
import FirmList from "./FirmList";
import { FloorsContext } from "../context";

const Ul = styled.ul`
  list-style: none;
  padding-inline-start: 0;
`;

const Li = styled.li`
  height: 272px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > div:first-child {
    width: 13.33%;
  }
  & > div:nth-child(2) {
    width: 61.67%;
  }
  & > div:nth-child(3) {
    width: 25%;
  }
  &:not(:first-child) {
    & > div:nth-child(2) {
      width: 86.67%;
    }
  }
`;

const Floor = styled.span`
  display: block;
  font-size: 10rem;
  font-weight: 400;
  text-align: center;
  text-transform: uppercase;
`;

const FloorList = (props) => {
  const floors = useContext(FloorsContext);
  return (
    <Ul>
      {floors.length > 0 &&
        floors.map((el, index) => {
          return (
            <Li
              key={index}
              className={
                index !== floors.length - 1 ? "border-bottom border-gray" : ""
              }
            >
              <div>
                <Floor>{el.name.replace("Piętro ", "")}</Floor>
              </div>
              <div>
                {el.companies.length > 0 && (
                  <FirmList companies={el.companies} />
                )}
              </div>
              {index === 0 ? (
                <div>
                  <Img cl="mx-auto" src={toilet} alt="toilet" mw="155px" />
                </div>
              ) : (
                ""
              )}
            </Li>
          );
        })}
    </Ul>
  );
};
export default FloorList;
