import React, { useContext } from "react";
import styled from "styled-components";

import Img from "./UI/Img";
import { WeatherContext } from "../context";
import icons from "../icons";



const Wrapper = styled.div`
  padding-left: 7rem;
  p {
    margin: 0;
    font-size: 5rem;
    font-weight: 400;
  }
`;

const Temp = styled.div`
  font-size: 7.2rem;
  font-weight: 500;
  padding-right: 4rem;
  span {
    font-size: 4.2rem;
  }
`;
const Weather = (props) => {
  const weather = useContext(WeatherContext);
  return (
    <Wrapper>
      <p className="label">pogoda sopot:</p>
      <div className="d-flex justify-content-between">
        <Img src={icons[weather.icon]} alt={weather.icon} mw="72px" />
        <Temp>
          {weather.temperature} <span>o</span>C
        </Temp>
      </div>
      <p className="label text-right">{weather.label}</p>
      <p className="text-right">{weather.air_pollution}</p>
      <p className="label text-right">jakość powietrza</p>
    </Wrapper>
  );
};

export default Weather;
